import { useContext } from "react";
import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import api from "../utils/axios-utils";
import { enterPhoneSchema } from "../utils/regexHelpers";
import { useForm } from "react-hook-form";
import { StepContext } from "../Context/stepFormContext/stepContext";

//assets
import telephone from "../assets/telephone.svg";
import arrow from "../assets/Group 6.svg";

export default function EnterPhone() {
  // const [error, setError] = useState(false);

  const { setWelcomeStep, setWelcomeError, editMode } = useContext(StepContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(enterPhoneSchema),
  });

  // console.log("welcomeStep>>", welcomeStep);

  const onSubmit = async (data) => {
    // console.log(data);

    if (editMode) {
      const user_id = localStorage.getItem("initiatorId");
      const phone_number = data.phone_number;

      try {
        const res = await api.patch("auth/phone/update-phone-number", {
          user_id,
          phone_number,
        });

        // console.log("success>>", res);

        localStorage.setItem("phoneNo", data.phone_number);
        localStorage.setItem("hashedNo", res.data.data.phone_number);
        setWelcomeStep(1);
      } catch (err) {
        setWelcomeError(err.response.data.message);
      }
    }

    try {
      const res = await api.post("auth/phone/initiate", data);

      // console.log("success>>", res);

      localStorage.removeItem("phoneNo");
      localStorage.setItem("phoneNo", data.phone_number);
      localStorage.setItem("hashedNo", res.data.data.phone_number);
      localStorage.setItem("initiatorId", res.data.data.id);

      if (res.data.data.user_exists) {
        // res.data && window.location.replace("olduser");
        setWelcomeStep(2);
      } else {
        // res.data && window.location.replace("verify");
        setWelcomeStep(1);
      }
    } catch (err) {
      setWelcomeError(err.response.data.message);
      // notify()
      // console.log(err.response.data.message);
    }
  };

  return (
    <div className="flex flex-col lg:w-[400px] ">
      <div>
        <h1 className="text-2xl md:text-3xl mb-4">Get Started in 2 steps</h1>
        <p className="text-sm max-w-xs text-neutral-400 mb-4">
          Please provide your phone number to get started
        </p>
      </div>

      <div className="max-w-sm relative">
        <span className="border-[#2054D2] bg-[#2054D2] border-[1.5px] absolute w-2/4"></span>
        <span className="border-[#C5D4F6] bg-[#C5D4F6] border-[1.5px] absolute right-0 w-2/4"></span>

        <div className="max-w-sm px-4 py-6 bg-white rounded-md flex flex-col align items-center md:items-start">
          <p className="text-left mb-3 text-sm">Please Enter Phone Number</p>
          <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
            <div class="relative">
              <div class="absolute h-10 inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <img className="w-4" src={telephone} alt="" />
              </div>
              {/* <input
                                            type="text"
                                            {...register("phone_number")}
                                            placeholder='Phone Number'
                                            class="bg-gray-50 border  border-gray-300 text-gray-900 text-sm rounded-lg  block w-full  p-2.5   dark:border-gray-600/30 dark:placeholder-gray-400 focus:outline-none"
                                            /> */}

              <input
                type="text"
                {...register("phone_number")}
                placeholder="Phone number"
                class="placeholder:font-thin bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 block w-full pl-10 p-2.5   dark:border-gray-600/30 dark:placeholder-gray-400 focus:outline-none "
              />
            </div>

            <button className="flex items-center justify-center mt-3 w-full">
              Continue <img className="pl-2" src={arrow} alt="" />
            </button>
            <div class="text-red-600 mt-0 text-[11px] text-center">
              {errors?.phone_number && <p>{errors?.phone_number.message}</p>}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
