import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../assets/sparkles.json';
// import sparkle from "../assets/sparkles 1.png";



export default function SuccessModal({ toggle }) {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
        }
    };

    


    return (
            <div onClick={toggle} className='fixed top-0 left-0 h-screen w-screen bg-backgroundModal/60 flex justify-center items-center z-10'>
                <div className='relative bg-white rounded-md md:w-[40vw] h-screen md:h-[45vh] flex flex-col md:p-[30px] p-[20px] items-center justify-around'>
                    <div className='flex h-full items-center justify-center  flex-col w-full'>
                        <div className='p-[10px] flex justify-center items-center w-full mb-8'>
                            {/* <img src={sparkle} alt="" className='absolute'/> */}
                            <Lottie 
                            //  style={width: 300px; height: 300px}
                                options={defaultOptions}
                                height={120}
                                width={200}
                                style={{ position: 'absolute' }}
                                />
                            <h1 className="text-[26px] text-buttonColor">Successful</h1>
                        </div>
                        <p className='bg-backgroundLightModal mb-6 border-backgroundLightBorder border rounded p-4 font-thin antialiased text-sm text-center'>You can find your insurance details in your email and on the Aella App.</p>
                        <a href="https://play.google.com/store/apps/details?id=com.aella.comportal&hl=en&gl=UG" target='_blank' rel='noopener noreferrer' className=''>
                            <button className="flex items-center justify-center mt-3 w-full">Get the App</button>
                        </a>
                    </div>
                </div>
            </div>
        )
}










