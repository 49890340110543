const axios = require('axios');

const BASE_URL = process.env.REACT_APP_BASE_URL;

// const TOKEN = JSON.parse(localStorage.getItem('token'))['access_token']
const token = localStorage.getItem('token');

const api = axios.create({
    baseURL: BASE_URL, headers: {"Authorization" : `Bearer ${token}`} 
  /* other custom settings */
});

module.exports = api;

