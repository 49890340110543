import { useContext } from "react";
import { StepContext } from "../Context/stepFormContext/stepContext";

import closeIcon from "../assets/closeIcon.svg";

import { PaystackButton } from 'react-paystack';

const commafy = require('commafy');




export default function PaySummarySidebar({toggle, next}) {

    const LOGO = localStorage.getItem("insuranceLogo");
    const PRICE = localStorage.getItem("insurancePrice");
    const TITLE = localStorage.getItem("insuranceTitle");
    const CATEGORY = localStorage.getItem("insuranceCategory");
    const phone = localStorage.getItem("phoneNo");
    const NAME = localStorage.getItem("name");
    const SURNAME = localStorage.getItem("surname");

    const { setCurrentModal } = useContext(StepContext);
    // console.log("currentModal>>", currentModal)

    const email = localStorage.getItem("email");

    const PAYSTACK_PUBLIC_KEY = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;

    // console.log("paystack key>>", PAYSTACK_PUBLIC_KEY);

    const publicKey = PAYSTACK_PUBLIC_KEY
    // const publicKey = "pk_test_5b550bb53b99c98ca53f716411b001c4fc706000"
    const amount = PRICE*100 // Remember, set in kobo!
    // const [email, setEmail] = useState("")
    const name = NAME;



    const componentProps = {
        email,
        amount,
        metadata: {
            name,
            phone,
        },
        publicKey,
        text: "Pay Now",
        onSuccess: () =>
        // alert("Thanks for doing business with us! Come back soon!!"),
        setCurrentModal(3),
        // onClose: () => alert("Wait! You need this oil, don't go!!!!"),
    }



    return (
    <div className='fixed top-0 left-0 h-screen w-screen bg-backgroundModal/60 flex justify-center items-center z-10'>
        <div className='bg-white w-screen  md:w-[50vw] lg:w-[32vw] fixed right-0 h-screen p-5 flex flex-col justify-between'>

            <div className='flex flex-col w-full h-full'>
                <img onClick={toggle} src={closeIcon} alt="" className="object-cover max-w-[13px] cursor-pointer"/>

                <div className="flex mt-8  flex-col ">
                    <p className="text-lg font-medium">Payment Summary</p>
                    
                    <div className="flex items-center mt-3">
                        <img src={LOGO} alt="" className="rounded-full w-[45px]" />
                        <p className="text-sm ml-2 text-center">{TITLE}</p>
                    </div>
                </div>

                <div className="bg-backgroundColor2/100 mt-8 w-full rounded-lg">
                    <ul className="p-[8px] antialiased">
                        <li className="flex justify-between py-[8px] border-b  border-b-neutral-500/30">
                            <p className="font-thin">Plan</p>
                            <p className="font-medium">{CATEGORY}</p>
                        </li>
                        <li className="flex justify-between py-[8px] border-b border-b-neutral-500/30">
                            <p className="font-thin">Amount</p>
                            <p className="font-medium"><span className="px-1">&#8358;</span>{commafy(PRICE)}</p>
                        </li>
                        <li className="flex justify-between py-[8px] border-b border-b-neutral-500/30">
                            <p className="font-thin">First Name</p>
                            <p className="font-medium">{NAME}</p>
                        </li>
                        <li className="flex justify-between py-[8px] border-b border-b-neutral-500/30">
                            <p className="font-thin">Last Name</p>
                            <p className="font-medium">{SURNAME}</p>
                        </li> 
                        <li className="flex justify-between py-[8px]">
                            <p className="font-thin">Phone Number</p>
                            <p className="font-medium">{phone}</p>
                        </li>
                    </ul>
                </div>

                {/* <button onClick={next} className="flex items-center justify-center mt-4 w-full">Make Payments</button> */}
                <PaystackButton 
                        className="flex  items-center justify-center rounded-lg w-full mt-[20px]"
                        {...componentProps} />
            </div>
        </div>
    </div>
  )
}




