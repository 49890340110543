import React from 'react';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css';

export default function InsuranceCardLoader() {
    return (
    <>
    <div className="m-4 rounded-lg p-2 flex shadow-md bg-white max-h-[220px]">
                <Skeleton circle height={54} width={54} />
            <div className="flex flex-col ml-2 w-full"> 
                <div className="flex w-full justify-between  align-middle px-[4px] py-[11px]">
                        <Skeleton width={70} />
                        <Skeleton width={50} height={20} borderRadius="0.5rem"/>
                </div>
                <hr />
                <div className="flex flex-col">
                        <Skeleton width={120} height={15}/>
                        <Skeleton count={4} width={200} height={15}/>
                </div>
            </div>
    </div>

    <div className="m-4 rounded-lg p-2 flex shadow-md bg-white max-h-[220px]">
                <Skeleton circle height={54} width={54} />
            <div className="flex flex-col ml-2 w-full"> 
                <div className="flex w-full justify-between  align-middle px-[4px] py-[11px]">
                        <Skeleton width={70} />
                        <Skeleton width={50} height={20} borderRadius="0.5rem"/>
                </div>
                <hr />
                <div className="flex flex-col">
                        <Skeleton width={120} height={15}/>
                        <Skeleton count={4} width={200} height={15}/>
                </div>
            </div>
    </div>

    <div className="m-4 rounded-lg p-2 flex shadow-md bg-white max-h-[220px]">
                <Skeleton circle height={54} width={54} />
            <div className="flex flex-col ml-2 w-full"> 
                <div className="flex w-full justify-between  align-middle px-[4px] py-[11px]">
                        <Skeleton width={70} />
                        <Skeleton width={50} height={20} borderRadius="0.5rem"/>
                </div>
                <hr />
                <div className="flex flex-col">
                        <Skeleton width={120} height={15}/>
                        <Skeleton count={4} width={200} height={15}/>
                </div>
            </div>
    </div>

    <div className="m-4 rounded-lg p-2 flex shadow-md bg-white max-h-[220px]">
                <Skeleton circle height={54} width={54} />
            <div className="flex flex-col ml-2 w-full"> 
                <div className="flex w-full justify-between  align-middle px-[4px] py-[11px]">
                        <Skeleton width={70} />
                        <Skeleton width={50} height={20} borderRadius="0.5rem"/>
                </div>
                <hr />
                <div className="flex flex-col">
                        <Skeleton width={120} height={15}/>
                        <Skeleton count={4} width={200} height={15}/>
                </div>
            </div>
    </div>

    <div className="m-4 rounded-lg p-2 flex shadow-md bg-white max-h-[220px]">
                <Skeleton circle height={54} width={54} />
            <div className="flex flex-col ml-2 w-full"> 
                <div className="flex w-full justify-between  align-middle px-[4px] py-[11px]">
                        <Skeleton width={70} />
                        <Skeleton width={50} height={20} borderRadius="0.5rem"/>
                </div>
                <hr />
                <div className="flex flex-col">
                        <Skeleton width={120} height={15}/>
                        <Skeleton count={4} width={200} height={15}/>
                </div>
            </div>
    </div>

    <div className="m-4 rounded-lg p-2 flex shadow-md bg-white max-h-[220px]">
                <Skeleton circle height={54} width={54} />
            <div className="flex flex-col ml-2 w-full"> 
                <div className="flex w-full justify-between  align-middle px-[4px] py-[11px]">
                        <Skeleton width={70} />
                        <Skeleton width={50} height={20} borderRadius="0.5rem"/>
                </div>
                <hr />
                <div className="flex flex-col">
                        <Skeleton width={120} height={15}/>
                        <Skeleton count={4} width={200} height={15}/>
                </div>
            </div>
    </div>
    </>

    
    )
}
