import React, { useState, useEffect, useContext } from "react";
import api from "../utils/axios-utils";
import { Link } from 'react-router-dom';

// import { useJwt } from "react-jwt";


//sub component
import InsuranceCard from "../Components/InsuranceCard";
import InputRange from "../Components/Input";
import InsuranceCardLoader from "../utils/preloaders/insuranceCardLoader";
import Select, { components } from "react-select";


//modals and sidebars
import InsuranceInfoSideBar from "../Components/InsuranceInfoSidebar";
import UploadDetailsSidebar from "../Components/UploadDetailsSidebar";
import PaySummarySidebar from "../Components/PaySummarySidebar";
import SuccessModal from "../Components/SuccessModal";

//assets
import aellaLogo from "../assets/1.svg";
import page from "../assets/Next.svg";


// import SelectDropdown from "../Components/SelectDropdown";

//context
import { StepContext } from "../Context/stepFormContext/stepContext";




export default function Insurance() {
    const [isOpen, setIsOpen] = useState(false);
    const [planId, setId] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [provider, setProvider] = useState([]);
    const [providerId, setProviderId] = useState("");
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0);


    
    const [isLoading, setIsLoading] = useState(true);

    


    const {currentModal, setCurrentModal} = useContext(StepContext);

    // console.log("curretModal>>", currentModal);


    const nextModal = () => {
        setCurrentModal(currentModal + 1);
    };

    // console.log("currentGuy>>", currentModal)

    const toggle = () => {
        setIsOpen(!isOpen)
        setCurrentModal(0);
    }
    
    useEffect(() => {   
        const test = () => api.get("marketplace/providers")
        .then((res) => {
            // console.log("test>>>",res.data.data);
            if (res && res.data && res.data.data) {
                setCompanies(res.data.data);  /*set response data in items array*/
                setIsLoading(false);
            } else {
                setCompanies([]);  /*set response data in items array*/
            }
        })
        .catch((err) => console.log(err));
        test();
    }, []);


    useEffect(() => {   
        const url =
        providerId === ""
            ?  "marketplace/packages"
            : `marketplace/packages/provider/${providerId}`;
    
        api.get(url)
        .then((res) => {
            if (res && res.data && res.data.data) {
                setProvider(res.data.data);  /*set response data in items array*/
                setIsLoading(false);
            } else {
                setProvider([]);  /*set response data in items array*/
            }
            // setProvider(providerId);
        })

    }, [providerId]);

    // console.log("test>>", provider);

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <span>&#9662;</span>
            </components.DropdownIndicator>
        );
    };

    // const hospitalPlaceholder = (
    //     <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
    //         <img className="w-3" src={hospitalIcon} alt="" /> <span className="pl-3 p-2.5 text-sm font-thin">Select Preffered Hospital</span>
    //     </div>
    // );

    // console.log("companies>>>", companies);

    // console.log("omooooo>>>", providerId);

    const providers = companies;



    const defaultProvider = [{ label: "All", value: "" }]

    const options = providers?.map((provider) => {
        const id = provider.id;
        const name = provider.name;
        return {value: id, label: name}
    });

    const allProviders = options.concat(defaultProvider);

    

    const colourStyles = {
        control: (styles) => ({ ...styles, backgroundColor: "#F3F5FC", border: "none",   boxShadow: 'none', fontWeight: 100, cursor: "pointer",}),
        option: (styles, { isDisabled }) => {
            return {
            ...styles,
            fontWeight: 100,
            cursor: "pointer"
            // backgroundColor: "white",
            // color: "black",
            // cursor: isDisabled ? "not-allowed" : "default"
            };
        }
    };

    //   console.log("summmmmm>>>", sum)





    
    // const onProviderChange = async (e) => {                     
    //     setProviderId(e.target.value);
    // }


    const handleMin = (e) => {
        setMin(e.target.value);
        // if (e.target.value > 0) {
        //     setCannotInput(false);
        // }



        // const formatNumber = parseInt(e.target.value.replace(/,/g, '')).toLocaleString();
        // setMin(formatNumber);
        
        // setMin(e.target.value);
        // if (e.target.value > 0) {
        //     setCannotInput(false);
        // }

/////////

        // const string = e.target.value.replace(/,/g, '').toLocaleString();
        // const formatNumber = parseInt(string);
        // setMin(formatNumber);
        
        // // setMin(e.target.value);
        // if (e.target.value > 0) {
        //     setCannotInput(false);
        // }

        // console.log(string)
    }

    const handleMax = (e) => {
        // console.log ("Max>>>", e.target.value);
        setMax(e.target.value)
    }
    



    const modal = () => {
        switch(currentModal) {
        case 0:   return <InsuranceInfoSideBar
                                planId={planId} 
                                toggle= {toggle}
                                next={nextModal}
                        />
        case 1:   return <UploadDetailsSidebar
                            toggle= {toggle}
                            next={nextModal}
                            />
        case 2: return <PaySummarySidebar 
                            toggle= {toggle}
                            next={nextModal}/>;
        case 3:  return <SuccessModal
                            toggle= {toggle}
                        />
        default: return 
        }
    }

    // console.log(dropdown)


    return (
        <>
        {
            isOpen && 
            modal(isOpen) 
        }


        <div className="min-h-[100vh] w-full bg-white overflow-scroll">
            <div className="flex  px-4 h-full/10 md:px-[0]  md:mx-[7vw] 2xl:mx-[12vw]  py-[27px]">
            <Link exact to={"/"} className="">
                <img className="object-contain w-[72px]" src={aellaLogo} alt="aella-logo"/>
            </Link>
            </div>


            <div className="px-4 md:mx-[7vw] md:px-0 2xl:mx-[12vw]">
                <div>
                    <h1 className="text-2xl md:text-3xl mb-4">Secure your health</h1>
                    <p className="text-sm max-w-xs mb-4 font-thin antialiased  ">Get insurance coverage with Aella Care. You deserve the best so create a plan and enjoy a full and healthy life.</p>
                </div>
            </div>


            <div className="text-base mb-4 px-4 md:mx-[7vw] 2xl:mx-[12vw] md:px-0  md:flex justify-between align-middle items-center">
                <h1 className="md:w-[30vw] text-xl xs:mb-2 sm:mb-2 md:mb-0 ">Insurance Plans</h1>
                <div class="grid grid-cols-12 gap-4   w-full items-center">

                    <div class="col-start-1 col-span-3 md:col-span-1">Cost</div>
                    
                    <div class="col-start-4 md:col-span-3 col-span-4 md:col-start-2">
                        <div class="form-floating">
                            <InputRange onChange={handleMin} placeholder={"Min[₦]"} />
                        </div>
                    </div>


                    <div class="md:col-start-5 flex justify-center">-</div>

                    <div class="col-start-9 col-span-4 md:col-start-6 md:col-span-3">
                        <div class="form-floating">
                            <InputRange onChange={handleMax} placeholder={"Max[₦]"} />
                        </div>
                    </div>

                    <div class="col-start-1 antialiased col-span-3 md:col-start-9 md:col-span-1">Type</div>
                        <div class="col-start-4 col-span-9 md:col-start-10 md:col-span-4">
                        
                            <Select
                                styles={colourStyles}
                                options={allProviders}
                                isSearchable={false}
                                defaultValue={{ label: "All", value: "all" }}
                                className="form-control w-full"
                                components={{
                                    IndicatorSeparator: () => null,
                                    DropdownIndicator 
                                }}
                                onChange={(val) => setProviderId(val.value)}
                            />

                        </div>
                    </div>
            </div>

            {/* xs:bg-red-400 xxs:bg-gray-600 sm:bg-blue-400 md:bg-green-400 lg:bg-yellow-400 xl:bg-purple-400 2xl:bg-gray-500 */}


            {/* Grid area */}
        
            {/* grid lg:grid-cols-3 md:gap-4 xl:gap-1 md:mx-[7vw] bg-backgroundColor2 h-[500px] overflow-scroll */}

            <div className="grid xl:grid-cols-3 md:gap-4 xl:gap-1 md:mx-[7vw] 2xl:mx-[12vw] bg-backgroundColor2 xxs:min-h-[100vh] xs:min-h-[530px] sm:min-h-[70vh]   md:min-h-[70vh] xl:min-h-[60vh] 2xl:min-h-[50vh]  overflow-scroll">
                {
                    isLoading &&
                    <InsuranceCardLoader />
                }
                {min > 0 && max > 0 ? provider.filter(item => item.amount >= min && item.amount <= max).map(currProvider => {
                    return  <InsuranceCard 
                    title={currProvider.provider.name} 
                    logo={currProvider.provider.logo} 
                    price={currProvider.amount} 
                    category={currProvider.name} 
                    benefits={currProvider.benefits.slice(0, 4)}
                    toggleModal
                    isLoading={isLoading}
                    toggle= {() => {
                        setId(currProvider.id)
                        setIsOpen(!isOpen)
                    }}
                    id={currProvider.id}/> }) : provider.map ((currProvider) => (
                        <InsuranceCard 
                            title={currProvider.provider.name} 
                            logo={currProvider.provider.logo} 
                            price={currProvider.amount} 
                            category={currProvider.name} 
                            benefits={currProvider.benefits.slice(0, 4)}
                            toggleModal
                            isLoading={isLoading}
                            toggle= {() => {
                                setId(currProvider.id)
                                setIsOpen(!isOpen)
                            }}
                            id={currProvider.id}
                        /> ))
                }
            </div>




            <div className="flex px-4 py-2 md:mx-[6vw] 2xl:mx-[12vw] text-[#052164] invisible">
                1 of 100  
                <span className="flex ">
                    <img 
                        // onClick={() => setPageNumber(page => page - 1)} disabled={pageNumber === 1} 
                        className="pl-2 object-contain rotate-180 mx-3 cursor-pointer w-[15px]" src={page} alt="" />
                    <img 
                        // onClick={() => setPageNumber(page => page + 1)} disabled={pageNumber === 4}
                        className="pl-2 object-contain cursor-pointer w-[15px]" src={page} alt="" /> 
                </span> 
            </div>

             {/* <div className="flex px-4 py-4 md:mx-[6vw] 2xl:mx-[12vw]">
                {pageNumber} of 100  
                <span className="flex ">
                    <img 
                        onClick={() => setPageNumber(page => page - 1)} disabled={pageNumber === 1} 
                        className="pl-2 object-contain rotate-180 mx-2 cursor-pointer" src={page} alt="" />
                    <img 
                        onClick={() => setPageNumber(page => page + 1)} disabled={pageNumber === 4}
                        className="pl-2 object-contain cursor-pointer" src={page} alt="" /> 
                </span> 
            </div> */}
        </div>
        {/* <ReactQueryDevtools initialIsOpen={false} position="bottom-right"/> */}
        </>
    )
}


















