import React from "react";
import { useJwt } from "react-jwt";
import { Route, Redirect } from "react-router-dom";


export const ProtectedRoute = ( 
    {
    component: Component,
    ...rest
    }) => {
// console.log("rest", rest)
const token = localStorage.getItem('token');
const {isExpired } = useJwt(token);
    return (
        <Route
        {...rest}
        render={props => {
            if (isExpired) {
                return (
                    <Redirect
                    to={{
                        pathname: "/",
                        state: {
                        from: props.location
                        }
                    }}
                    />
                )
            } else {
                return <Component {...props} />;
            
            }
        }}
        />
    );
};
