import doubleTick from "../assets/Double-check.svg";

const commafy = require('commafy');


export default function InsuranceCard({title, logo, price, category, benefits, toggle, id}) {
    
    // console.log("LOADING>>", isLoading);
    // console.log("ids>>", id);
    const logId = () => {
        toggle();
        
        localStorage.setItem('insuranceLogo', logo);
        localStorage.setItem('insurancePrice', price);
        localStorage.setItem('insuranceTitle', title);
        localStorage.setItem('insuranceCategory', category);
        localStorage.setItem('insuranceId', id);

        // console.log("finally>>",id);
        // console.log("logo>>", logo);
    }

    return (
        <>
        <div onClick={logId} className="m-4 rounded-lg p-2 flex hover:shadow cursor-pointer border border-[#E1EAFF] bg-white xxs:max-h-[230px] sm:max-h-[220px] md:max-h-[200px] xl:max-h-[210px] xs:max-h-[220px]">
            <img src={logo} alt="" className="rounded-full bg-gray-500/10 h-[54px] w-[54px] object-contain"/>

            <div className="flex flex-col ml-2 w-full " > 
                <div className="flex w-full justify-between  align-middle px-[4px] py-[11px]">
                    <p className="text-sm font-light max-w-xs w-[full/90]">{title}</p>
                    <p className="bg-buttonColor text-sm font-light text-white mr-[0] px-[10px] py-[1px] rounded-full w-[full/10] flex text-center cursor-pointer" >Get Plan</p>
                </div>
                <hr />
                <div className="flex flex-col">
                    <div><span className="">&#8358;</span><span className="text-2xl font-normal px-1">{commafy(price)}</span>{category}</div>

                    <ul className="text-sm antialiased font-thin grid md:grid-cols-2 md:gap-2 items-start xs:text-xs xxs:text-xs  overflow-scroll">
                    {benefits.map((benefit) => (
                        <div className=" flex flex-row w-full items-start">
                            <img src={doubleTick} alt="" className="object-contain w-[12px] mr-2 py-1"/>
                            <li className="flex items-start line-clamp-2 w-full">{(benefit.description)}</li>
                        </div>
                        ))}
                    </ul>
                </div>
                
            </div>
        </div>
        </>
)}
    


// {(t.description).slice(0,4)}