import { useState } from "react";
import api from "../utils/axios-utils";
import { useContext } from "react";
import { StepContext } from "../Context/stepFormContext/stepContext";

//assets
import hidepass from "../assets/hidePass.png";
import showpass from "../assets/showPass.png";
import passArea from "../assets/passArea.png";

export default function EmailLogin() {
    

    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    // const [error, setError] = useState(false);
    // const [errMess, setErrMessage] = useState(null);
    const [passwordShown, setPasswordShown] = useState(false);

    const { setWelcomeError } = useContext(StepContext);



    const handleSubmit = async (e) => {
        e.preventDefault();
        // setError(false);
        try {
            const res = await api.post("user/login", {
                email: email,
                password: password

            }); 
            // localStorage.removeItem("phoneNo");
            localStorage.setItem('token', res.data.data.token);
            localStorage.setItem('initiatorId', res.data.data.id);

            res.data && window.location.replace("insurance");
            // console.log("success>>", res);
        } catch(err) {
            setWelcomeError(err.response.data.message)
            // console.log(err.response.data.message);
        }
    };



    return (
    <div className="flex flex-col lg:w-[400px]" >
        <div>
            <h1 className="text-2xl md:text-3xl mb-4">Get Started in 2 steps</h1>
            <p className="text-sm max-w-xs text-neutral-400 mb-4">You already have an account on the Aella App. Kindly Login your credentials to proceed.</p>
        </div>

        <div className="max-w-sm px-4 py-6 bg-white flex flex-col align items-center border-t-4 border-buttonColor">
            <div className="flex flex-col w-full">
                <div className=" flex justify-between text-base w-full mb-3 ">
                    <p className="text-center text-sm font-medium">Login</p>
                    {/* <p className="text-buttonColor text-base">{email == null ? "us**********@gmail.com" : email } </p> */}
                </div>
            </div>

            <div className="max-w-sm w-full pb-4  rounded-md flex flex-col align items-center">
                <form onSubmit={handleSubmit} className="w-full">

                    <div className="relative">
                        <div class="absolute h-10 inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <img className="w-5 " src={passArea} alt="" />
                        </div>
                        <div className="flex text-center">
                            <input 

                            onChange={e=>setEmail(e.target.value)}
                            type="email"
                            className='mb-4 placeholder:font-thin bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 block w-full pl-10 p-2.5   dark:border-gray-600/30 dark:placeholder-gray-400 focus:outline-none' placeholder='Enter Email'/>
                        </div>
                    </div>

                    <div className="relative items">
                        <div class="absolute h-10 inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <img className="w-5 " src={passArea} alt="" />
                        </div>
                        <div className="flex text-center">
                            <input 

                            onChange={e=>setPassword(e.target.value)}
                            className='placeholder:font-thin bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 block w-full pl-10 p-2.5   dark:border-gray-600/30 dark:placeholder-gray-400 focus:outline-none' placeholder='Enter Password'
                            type={passwordShown ? "text" : "password"} 
                            />
                        </div>

                        <div className="absolute  inset-y-0 right-0 flex items-center px-2  bg-gray-50  border-gray-300 border rounded-r-lg border-l-0">
                            <img onClick={() => setPasswordShown(!passwordShown)} className="icon cursor-pointer object-cover w-5" src={passwordShown ? showpass : hidepass} alt="" />
                        </div>
                    </div>


                    <button className="flex items-center justify-center mt-3 w-full">Login</button>
                </form>
            </div>
            
            <div className="w-full">
                <p className="text-left text-xs cursor-pointer">Forgot Password?</p>
            </div>
        </div>
    </div>
  )
}
