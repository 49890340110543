import { useContext } from "react";
import { StepContext } from "../Context/stepFormContext/stepContext";
import Div100vh from 'react-div-100vh';


//components
import EnterPhone from "../Components/EnterPhone";
import OtpVerification from "../Components/OtpVerification";
import EmailLogin from "../Components/EmailLogin";
import ErrorBanner from "../Components/ErrorBanner";



//assets
import aellaLogo from "../assets/1.svg";
import illustration from "../assets/image 30.svg";



export default function Welcome() {

    // const [error, setError] = useState(false)

    const { welcomeStep, welcomeError } = useContext(StepContext);

    // console.log("step>>", welcomeStep)


    const card = () => {
        switch(welcomeStep) {
        case 0:   return <EnterPhone />
        case 1:   return <OtpVerification />
        case 2: return <EmailLogin />
        default: return 
        }
    }
 


    return (
            <Div100vh className="flex flex-col justify-start w-screen h-screen">
                <div className="flex  h-full/10 mx-auto md:mx-[7vw] 2xl:mx-[12vw]">
                    <img className="object-contain w-[72px]" src={aellaLogo} alt="aella-logo"/>
                </div>
                

                {welcomeError && 
                    <ErrorBanner 
                    />
                }

                <div className="flex justify-between align-middle items-center h-full/90">
                    <div className="flex flex-col w-full lg:flex-row xl:w-[100%] lg:w-[1000px] xl:justify-between items-center  md:mx-[7vw] 2xl:mx-[12vw]">
                        {card()}
                        <img className='hidden lg:block w-2/4 max-w-2xl h-full object-contain' src={illustration} alt="" />
                    </div>
                </div>
                
            </Div100vh>
    )
}
