import { useState,useEffect } from "react";
import { useQuery } from "react-query";
import api from "../utils/axios-utils";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { Link } from 'react-router-dom';

import { uploadDetailsSchema } from "../utils/regexHelpers";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// import * as Yup from "yup";
// import moment from "moment";



import closeIcon from "../assets/closeIcon.svg";
import personIcon from "../assets/person.svg";
import emailIcon from "../assets/email.svg";
// import birthIcon from "../assets/birthday.svg";
import genoIcon from "../assets/select.svg";
import genderIcon from "../assets/relationship.svg";
import telephone from "../assets/telephone.svg";
import houseIcon from "../assets/house.svg";
import uploadIcon from "../assets/upload.svg";
import hospitalIcon from "../assets/hospital.svg";
import spinner from "../assets/loader.svg";
import birthday from "../assets/birthday.svg";
import errorIcon from "../assets/Error.svg";
// import closeError from "../assets/close-error.svg";



export default function UploadDetailsSidebar({toggle, next}) {

    const [error, setError] = useState(false);
    const [pic, setPic] = useState(false);
    const [loading, setLoading] = useState(false);
    const [imgError, setImgError] = useState(null);
    const [file, setFile] = useState(null);
    const [options, setOptions] = useState(null);
    const [loadSubmit, setLoadSubmit] = useState(false);
    // const [startDate, setStartDate] = useState(null);

    // console.log("loadsubmit>>", loadSubmit);

    // setTimeout( alert("hddhhd"), 30000);
    // console.log( "loading>>", loading)


    //handle image upload
    const uploadImage = async (e) => {
        setLoading(true)
        e.preventDefault();

        // console.log("res>>");
        if (e.target.files[0].name) {
            const data = new FormData();

            let file = e.target.files[0];            

            
            if (file.size > 5e6) {
            setImgError("Please upload a file smaller than 5 MB")
            setLoading(false)

            } else {

            data.append("file", e.target.files[0]);
            // console.log("file", e.target.files[0])

            try {
                const res = await api.post("user/file", data);
                const img = URL.createObjectURL(e.target.files[0])
                setImgError(null)
                setPic(img)
                setFile(res.data.data.file);
                setLoading(false)
                
                
                // console.log("res>>", res.data.data.file);
            } catch(err) {
                // console.log("error>>", err.response.data.message)
                setImgError(err.response.data.message)
                setLoading(false)
            }
            }

        }
        setLoading(false)
    };

    // console.log("file>>>", file);


    //return list of hospitals
    const providerId = localStorage.getItem('provider');

    const { data } = useQuery("hospital-data", () => {
        return api.get(`marketplace/hospitals/provider/${providerId}`)
    })

    // console.log("hospitals>>", data);

   

    // console.log("hospital>>>", data?.data.data);

    // const options = hospitals?.map((hospital) => {
    //     const title = hospital.name;<br />
    //     const address = hospital.address

    //     const word = `${title} ${address}`

    //     return {value: hospital.name, label: word}
    // });

    useEffect(() => {
        const hospitals = data?.data.data;
        const Options=  hospitals?.map((hospital) => {
        const title = hospital.name;<br />
        const address = hospital.address
    
        const word = `${title} - ${address}`
            //   console.log("wprds",word)
        return {value: hospital.name, label: word}

        });
        setOptions(Options)
        }, [data?.data?.data])

    
// hosp.forEach(function (item, index) {
//   console.log("test>>>>", item, index);
// });









    const { register, handleSubmit, formState: { errors }, control } = useForm({
            resolver: yupResolver(uploadDetailsSchema)
            // console.log(errors);
    });
    

    //local storage
    const packageId = localStorage.getItem("insuranceId");
    const initEmail = localStorage.getItem("email");
    const initId = localStorage.getItem("initiatorId");

    const details = {
        free_plan: false,
        package_id: packageId,
        initiator_email: initEmail,
        initiator_id: initId,
        platform: "iOS",
        file: file
    }


    //SUBMIT 
    const onSubmit =  async(data) => {
    // console.log("submitted data>>", data);

    const {phone, email, first_name, last_name, gender} = data;
    // const value = values;

    localStorage.setItem('phoneNo', phone);
    localStorage.setItem('email', email);
    localStorage.setItem('name', first_name);
    localStorage.setItem('surname', last_name);

    const finalPayload = {
        ...data,
        ...details
    }

    // console.log("final Payload>>", finalPayload)

    const registerUser = {
        "first_name": first_name,
        "last_name": last_name,
        "email": email,
        "gender": gender  
    }

    try {
        setLoadSubmit(true)
        await api.post("auth/phone/update", registerUser);   //register user
        
        try {
            setLoadSubmit(true)
            await api.post("marketplace/plans/quick", finalPayload);   //upload details
            next()
            
        } catch (err) {
            setError(err.response.data.message);
            setLoadSubmit(false)
        }
    
    } catch (err) {
        try {
            setLoadSubmit(true)
            await api.post("marketplace/plans/quick", finalPayload);   //upload details
            next()
            
        } catch (err) {
            setError(err.response.data.message);
            setLoadSubmit(false);
        }
        // setError(err.response.data.message);
    }
}


    
        

    // const DropdownIndicator = props => {
    //     return (
    //       <components.DropdownIndicator {...props}>
    //         <span>&#9662;</span>
    //       </components.DropdownIndicator>
    //     );
    //   };
    const customStyles = {
        control: base => ({
            ...base,
            height: 46,
            borderRadius: 8,
        })
    };

    const genotype = [
        { label: "AA", value: "AA" },
        { label: "AS", value: "AS" },
        { label: "AC", value: "AC" },
        { label: "SC", value: "SC" },
        { label: "SS", value: "SS" },
    ];
    
    const genders = [
        { label: "Male", value: "Male" },
        { label: "Female", value: "Female" },
        // { label: "Others", value: "Others" },
    ];

    const states = [
        { label: "Abia", value: "Abia" },
        { label: "Adamawa", value: "Adamawa" },
        { label: "Akwa Ibom", value: "Akwa Ibom" },
        { label: "Anambra", value: "Anambra" },
        { label: "Bauchi", value: "Bauchi" },
        { label: "Bayelsa", value: "Bayelsa" },
        { label: "Benue", value: "Benue" },
        { label: "Borno", value: "Borno" },
        { label: "Cross River", value: "Cross River" },
        { label: "Delta", value: "Delta" },
        { label: "Ebonyi", value: "Ebonyi" },
        { label: "Edo", value: "Edo" },
        { label: "Ekiti", value: "Ekiti" },
        { label: "Enugu", value: "Enugu" },
        { label: "Gombe", value: "Gombe" },
        { label: "Imo", value: "Imo" },
        { label: "Jigawa", value: "Jigawa" },
        { label: "Kaduna", value: "Kaduna" },
        { label: "Kano", value: "Kano" },
        { label: "Katsina", value: "Katsina" },
        { label: "Kebbi", value: "Kebbi" },
        { label: "Kogi", value: "Kogi" },
        { label: "Kwara", value: "Kwara" },
        { label: "Lagos", value: "Lagos" },
        { label: "Nasarawa", value: "Nasarawa" },
        { label: "Niger", value: "Niger" },
        { label: "Ogun", value: "Ogun" },
        { label: "Ondo", value: "Ondo" },
        { label: "Osun", value: "Osun" },
        { label: "Oyo", value: "Oyo" },
        { label: "Plateau", value: "Plateau" },
        { label: "Rivers", value: "Rivers" },
        { label: "Sokoto", value: "Sokoto" },
        { label: "Taraba", value: "Taraba" },
        { label: "Yobe", value: "Yobe" },
        { label: "Zamfara", value: "Zamfara" },
        { label: "FCT", value: "FCT" },

    ]

    const genoPlaceholder = (
        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <img className="w-3" src={genoIcon} alt="" /> <span className="pl-3 p-2.5 text-base font-thin text-gray-400">Genotype</span>
        </div>
    );

    const genderPlaceholder = (
        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <img className="w-3" src={genderIcon} alt="" /> <span className="pl-3 p-2.5 text-base font-thin text-gray-400">Gender</span>
        </div>
    );

    const hospitalPlaceholder = (
        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <img className="w-3" src={hospitalIcon} alt="" /> <span className="pl-3 p-2.5 text-base font-thin text-gray-400">Select Preferred Hospital</span>
        </div>
    );

    const home = (
        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <img className="w-3" src={houseIcon} alt="" /> <span className="pl-3 p-2.5 text-base font-thin text-gray-400">State of Residence</span>
        </div>
    );




    return (
    <div className='fixed top-0 left-0 h-screen w-screen bg-backgroundModal/60 flex justify-center items-center z-10'>
        <div className='bg-white w-screen md:w-[50vw] h-[100vh] overflow-scroll lg:w-[32vw] fixed right-0  p-5 flex flex-col justify-between'>

            <div className='flex flex-col w-full h-full'>
            <div className='flex flex-col w-full h-full'>
                <img onClick={toggle} src={closeIcon} alt="" className="object-cover max-w-[13px] cursor-pointer"/>
                

                <div onSubmit={handleSubmit(onSubmit)} className="flex mt-8  flex-col">
                    <p className="text-lg font-normal">Please provide beneficiary details</p>
                    <div className="flex items-center mt-3 flex-col">

                {
                    error && 
                        // < ToastContainer 
                        // class="flex-1 items-center justify-center text-center"
                        // />
                        <div class="z-20 absolute bg-[#F0D5C7]  alert py-3 xs:py-2 text-base  justify-center flex text-center items-center w-full alert-dismissible fade show" role="alert">
                        <div className="flex justify-between xs:mx-[7vw]  md:mx-[2vw]  w-full items-center text-center">
                            <div className="flex items-center">
                                <img src={errorIcon} alt="" />
                                <div class="ml-3 xs:text-xs">
                                    {error}
                                </div>
                            </div>

                            <img onClick={() => setError(false)} src={closeIcon} alt="" className="cursor-pointer"/>
                        </div>
                    </div>
                }
                        
                        <form  className="w-full flex flex-col h-full" id="FORMID">
                            <div className="bg-backgroundColor2/100  p-4 overflow-scroll max-h-[72vh] 2xl:max-h-[80vh]">
                                
                                
                                <div class="w-full relative">
                                    <label
                                        class="flex overflow-clip  justify-center items-center w-full h-24  transition bg-backgroundColor2/100 border-2 border-blue-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                                        <span class="flex items-center space-x-2 absolute">
                                            {/* {loading} */} 
                                            
                                            <img src={uploadIcon} className={`object-contain w-[32px] ${(pic || loading) && ("invisible")}`} alt="" />
                                            {/* <img src={spinner} alt="" className="w-[35px]" /> */}
                                            <span class="font-medium text-gray-600 w-full flex hover:animate-pulse items-center">
                                                    <div  className={`flex flex-col text-center text-sm font-light    ${(pic || loading) && ("invisible")}`}>Upload Profile Picture <span>(jpg, png)</span></div> 
                                                    {/* { loading && <img src={spinner} alt="" className="w-[35px]" />
                                            } */}
                                                    <span class="ml-[10px] bg-gray-800/20 text-sm font-light items-center  px-[8px] rounded-full  flex text-center cursor-pointer border-neutral-600/5">{pic ? ("Edit Image") : ("Add Image")}</span>
                                            </span>
                                        </span>
                                        
                                        <input
                                        class="hidden"
                                        type="file"
                                        // accept="image/x-png,image/jpeg"
                                        accept="image/png, image/gif, image/jpeg, image/jpg" 
                                        // {...register("file")}
                                        onChange={e => {
                                        uploadImage(e);
                                        }}
                                    />
                                        {/* <input type="pic" name="file_upload" class="hidden" /> */}
                                        <div className="flex w-full relative items-center">
                                        {pic && (
                                            <img src={pic} alt="preview" className=" ml-[40px] object-cover rounded-full h-20 w-20 bg-gray-300"/>
                                        )}
                                        {/* { loading && <img src={spinner} alt="" className="w-[35px] absolute ml-[60px]" />
                                            } */}
                                            { loading && <img src={spinner} alt="" className="w-[35px] absolute ml-[60px]" />
                                            }
                                        </div>
                                    </label>
                                    <div class="text-red-600 mt-0 text-[11px]">
                                        {imgError}
                                    </div>

                                    {/* <div class="text-red-600 mt-0 text-[11px]">
                                        {errors?.file && <p>{errors?.file.message}</p>}
                                    </div> */}
                                </div>
                                
                                <div class="relative mt-4">
                                        <div class="absolute h-11 inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                            <img className="w-3" src={personIcon} alt="" />
                                        </div>
                                        <input
                                            type="text"
                                            {...register("first_name")}
                                            placeholder="First Name"
                                            class="placeholder:font-thin font-thin bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5   dark:border-gray-600/30 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            />
                                        <div class="text-red-600 mt-0 text-[11px]">
                                        {errors?.first_name && <p>{errors?.first_name.message}</p>}
                                        </div>
                                </div>

                                <div class="relative mt-4">
                                        <div class="absolute h-11 inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                            <img className="w-3" src={personIcon} alt="" />
                                        </div>
                                        <input
                                            type="text"
                                            {...register("last_name")}
                                            placeholder="Last Name"
                                            class="placeholder:font-thin font-thin bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5   dark:border-gray-600/30 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            />
                                        <div class="text-red-600 mt-0 text-[11px]">
                                        {errors?.last_name && <p>{errors?.last_name.message}</p>}
                                        </div>
                                </div>

                                <div class="relative mt-4">
                                        <div class="absolute h-11 inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                            <img className="w-3" src={emailIcon} alt="" />
                                        </div>
                                        <input
                                            type="text"
                                            {...register("email")}
                                            placeholder="Email Address"
                                            class="placeholder:font-thin font-thin bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5   dark:border-gray-600/30 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            />
                                        <div class="text-red-600 mt-0 text-[11px]">
                                        {errors?.email && <p>{errors?.email.message}</p>}
                                        </div>
                                </div>
                                

                                {/* <div className="mt-4">
                                        <input
                                            type="date"
                                            {...register("date_of_birth")}
                                            placeholder="Date of birth" 
                                            className="placeholder:font-thin border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-[#F9FAFB]   dark:placeholder-red-900  dark:text-black focus:outline-none"
                                            />
                                        <div class=" text-red-600 mt-0 text-[11px]">
                                        {errors?.date_of_birth && <p>{errors?.date_of_birth.message}</p>}
                                        </div>
                                </div> */}

                                <div className="mt-4 relative">
                                    <div class="absolute h-11 inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                        <img className="w-3  z-20" src={birthday} alt="" />
                                    </div>


                                    <Controller
                                        name="date_of_birth"
                                        control={control}
                                        render={({ field }) => (
                                            <DatePicker 
                                            dateFormat="dd-MM-yyyy"
                                                placeholderText={'Date of birth'}  
                                                onChange={(date) => field.onChange(date)}
                                                selected={field.value}
                                                className="h-11 w-full font-thin rounded-lg pl-10 border dark:border-gray-600/30 placeholder:font-thin text-gray-900 text-base" 
                                            />
                                        )}
                                    />

                                        <div class=" text-red-600 mt-0 text-[11px]">
                                        {errors?.date_of_birth && <p>{errors?.date_of_birth.message}</p>}
                                        </div>
                                </div>


                                <div class="relative mt-4">
                                    <Controller
                                        name="state_of_residence"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                        <Select
                                            options={states}
                                            placeholder={home}
                                            className="form-control w-full font-thin"
                                            value={states.find((c) => c.value === value)}
                                            onChange={(val) => onChange(val.value)}
                                            components={{
                                                IndicatorSeparator: () => null,
                                            }}
                                            styles={customStyles}
                                        />
                                        )}
                                    />

                                        <div class="text-red-600 mt-0 text-[11px]">
                                        {errors["state_of_residence"]?.message}
                                        </div>
                                </div>


                                <div class="relative mt-4">
                                        <div class="absolute h-11 inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                            <img className="w-3" src={houseIcon} alt="" />
                                        </div>
                                        <input
                                            type="text"
                                            {...register("address")}
                                            placeholder="Home Address"
                                            class="placeholder:font-thin font-thin bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5   dark:border-gray-600/30 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            />
                                        <div class="text-red-600 mt-0 text-[11px]">
                                        {errors?.address && <p>{errors?.address.message}</p>}
                                        </div>
                                </div>

                                <div class="relative mt-4">
                                        <div class="absolute h-11 inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                            <img className="w-3" src={telephone} alt="" />
                                        </div>
                                        <input
                                            type="number"
                                            {...register("phone")}
                                            placeholder="Phone number"
                                            class="placeholder:font-thin font-thin bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5   dark:border-gray-600/30 dark:placeholder-black-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            />
                                        <div class=" text-red-600 mt-0 text-[11px]">
                                        {errors?.phone && <p>{errors?.phone.message}</p>}
                                        </div>
                                </div>

                                <div class="relative mt-4">
                                    <Controller
                                        name="gender"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                        <Select
                                            options={genders}
                                            placeholder={genderPlaceholder}
                                            className="form-control font-thin w-full text-base"
                                            value={genders.find((c) => c.value === value)}
                                            onChange={(val) => onChange(val.value)}
                                            components={{
                                                IndicatorSeparator: () => null,
                                            }}
                                            styles={customStyles}
                                        />
                                        )}
                                    />

                                        <div class="text-red-600 mt-0 text-[11px]">
                                        {errors["gender"]?.message}
                                        </div>
                                </div>

                                <div class="relative mt-4 text-sm">
                                    <Controller
                                        name="genotype"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                        <Select
                                            options={genotype}
                                            placeholder={genoPlaceholder}
                                            className="form-control font-thin w-full"
                                            value={genotype.find((c) => c.value === value)}
                                            onChange={(val) => onChange(val.value)}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            styles={customStyles}
                                        />
                                        )}
                                    />

                                        <div class="text-red-600 mt-0 text-[11px]">
                                        {errors["genotype"]?.message}
                                        </div>
                                </div>

                                <div class="relative mt-4 text-sm">
                                    <Controller
                                        name="hospital"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                        <Select
                                            options={options}
                                            placeholder={hospitalPlaceholder}
                                            className="form-control w-full cursor-pointer font-thin"
                                            value={genders.find((c) => c.value === value)}
                                            onChange={(val) => onChange(val.value)}
                                            components={{
                                                IndicatorSeparator: () => null,
                                                // DropdownIndicator 
                                            }}
                                            styles={customStyles}
                                            // optionRenderer={customOption}
                                
                                            // styles={colourStyles}

                                        />
                                        )}
                                    />

                                        <div class="text-red-600 mt-0 text-[11px]">
                                        {errors["hospital"]?.message}
                                        </div>
                                </div>

                            </div>
                            
                            {/* <button className="flex   items-center justify-center  w-full z-20" >Submit</button> */}
                            
                        </form>
                    </div>

                </div>

            </div>
            </div>
            {/* <button className={`flex  items-center justify-center  w-full z-10 cursor-pointer ${loadSubmit && ("cursor-not-allowed relative")}`} disabled={loadSubmit}  form="FORMID">Submit <img src={spinner} alt="" className={`w-[35px] absolute ml-[90px] ${(!loadSubmit) && ("invisible")}`} /></button> */}
            <button disabled={loadSubmit} className="flex items-center justify-center  w-full z-10 cursor-pointer"   form="FORMID">{ loadSubmit ? (<img src={spinner} alt="" className="w-[35px]"/>) : ("Submit") }</button>
        </div>
    </div>
    )
};
