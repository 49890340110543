import * as Yup from "yup";
import moment from "moment";





export const uploadDetailsSchema = Yup.object().shape({
    first_name: Yup.string()
        .required("Name is required")
        .matches(/^[aA-zZ\s]+$/, "Name must be a letter")
        ,
    last_name: Yup.string()
        .required("Surname is required")
        .matches(/^[aA-zZ\s]+$/, "Surname must be a letter")
        ,
    email: Yup.string().email().required("Email is required"),
    date_of_birth: Yup.string()
        .required("Date of birth is required")
        .nullable()
        .test("DOB", "Please choose a valid date of birth", (value) => {
            return moment().diff(moment(value), "day") > 0;
        })
        .test("DOB", "You must be below 60 years to purchase a plan", (value) => {
            return moment().diff(moment(value), "years") < 60;
        }),
    genotype: Yup.mixed()
        .required("Genotype is required")
        .oneOf(["AA", "AS", "AC", "SC", "SS"]),
    gender: Yup.mixed()
        .required("Gender is required")
        .oneOf(["Male", "Female", "Others"]),
    state_of_residence: Yup.mixed()
    .required("State of residence is required")
    .oneOf(["Abia", "Adamawa", "Akwa Ibom", "Anambra", "Bauchi", "Bayelsa", "Benue", "Borno", "Cross River", "Delta", "Ebonyi", "Edo", "Ekiti", "Enugu", "Gombe", "Imo", "Jigawa", "Kaduna", "Kano", "Katsina", "Kebbi", "Kogi", "Kwara", "Lagos", "Nasarawa", "Niger", "Ogun", "Ondo", "Osun", "Oyo", "Plateau", "Rivers", "Sokoto", "Taraba", "Yobe", "Zamfara", "FCT" ]),
    address: Yup.string()
    .required("Address is required"),
    phone: Yup.string()
        .required("Phone number is Required")
        .matches(
        /^[0-9]{11}$/,
        "Please enter at least 11 digits",
    ),
    hospital: Yup.string()
    .required("Hospital is required"),

    // file: Yup.mixed()
    // .test("required", "Please select a file", value => {
    //     return value && value.length
    // })
    // state_of_residence: Yup.mixed()
    //     .required("State of residence is required")
    //     .oneOf(["Abia", "Adamawa", "Akwa Ibom", "Anambra", "Bauchi", "Bayelsa", "Benue", "Borno", "Cross River", "Delta", "Ebonyi", "Edo", "Ekiti", "Enugu", "Gombe", "Imo", "Jigawa", "Kaduna", "Kano", "Katsina", "Kebbi", "Kogi", "Kwara", "Lagos", "Nasarawa", "Niger", "Ogun", "Ondo", "Osun", "Oyo", "Plateau", "Rivers", "Sokoto", "Taraba", "Yobe", "Zamfara", "FCT" ])
    

});




export const enterPhoneSchema = Yup.object().shape({
    phone_number: Yup.string()
        .required("Phone number is Required")
        .matches(
        /^[0-9]{11}$/,
        "Please enter a valid 11 digit phone number"
    )
});






