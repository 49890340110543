import { useContext } from 'react';
import { StepContext } from "../Context/stepFormContext/stepContext";

import errorIcon from "../assets/Error.svg";
import closeError from "../assets/close-error.svg";

export default function ErrorBanner() {

    const { welcomeError, setWelcomeError } = useContext(StepContext);

    return (
                <div class="absolute bg-[#F0D5C7] alert py-3 xs:py-2  mt-20 text-base  justify-center flex text-center items-center w-full alert-dismissible fade show" role="alert">
                    <div className="flex justify-between xs:mx-[7vw]  md:mx-[7vw] 2xl:mx-[12vw] w-full items-center">
                        <div className="flex items-center">
                            <img src={errorIcon} alt="" />
                            <div class="ml-3 xs:text-xs">
                                {welcomeError}
                            </div>
                        </div>

                        <img onClick={() => setWelcomeError(false)} src={closeError} alt="" className="cursor-pointer"/>
                    </div>
                </div>
    )
}
