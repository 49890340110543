import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css';

export default function InsuranceInfoLoader() {
    return (
        <div className='z-0'>
        <div className="flex mt-8">
            <Skeleton circle height={45} width={45} />
            <div className="ml-4">
                <p className="text-sm"><Skeleton width={60} /></p>
                <p><Skeleton width={120} /></p>
            </div>
        </div>

        <div>
            <p className="text-sm mt-6 mb-2"><Skeleton width={50} /></p>
            <ul className="bg-backgroundColor2 px-4 py-2 rounded overflow-scroll max-h-[550px]">
                    <li className="max-w-[70%] md:max-w-[80%] flex font-thin items-start my-1 text-sm"><span className="object-contain max-w-[16px] mr-4 py-1"><Skeleton circle style={{marginBottom: "14px"}} count={15}  height={15} width={15} /></span><Skeleton style={{marginBottom: "14px"}} count={15} width={340} height={15}/></li>
            </ul>
        </div>
        </div>
    )
}
















