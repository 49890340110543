import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ProtectedRoute } from './utils/protected.route';

import Welcome from './Pages/Welcome';
import Insurance from "./Pages/Insurance";
import ErrorPage from './Pages/ErrorPage';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      
      <Router>
        <div>
          <Switch>
            <Route exact path="/">
                <Welcome />
            </Route>
            <ProtectedRoute exact path="/insurance" component={Insurance} />
            <Route path="*" component={ErrorPage} />
          </Switch>
        </div> 
      </Router>
    
    </QueryClientProvider>
  );
}

export default App;
