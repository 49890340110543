import { useQuery, useQueryClient } from "react-query";
import api from "../utils/axios-utils";

const fetchPackageData = ({ queryKey }) => {
    const planId = queryKey[1];   //destructured planId param passed as prop where queryKey[1] is the id
    return api.get(`marketplace/packages/${planId}`)
}

export const usePackageData = (planId) => {
    const queryClient = useQueryClient();

    return useQuery(["package", planId], fetchPackageData, {
        initialData: () => {
            const plan = queryClient.getQueryData("planInfo") 
            ?.data?.find((plan) => plan.id === parseInt(planId))

            if (plan) {
                return { data: plan }
                } else {
                    return undefined
                }
            },
    })
}