import React from 'react';
import ReactDOM from 'react-dom/client';
//import "antd/dist/antd.min.css";
import "./index.css";
import App from './App';
// import { AppContextProvider } from './Context/AppContext';
import { StepContextProvider } from './Context/stepFormContext/stepContext';
import 'tw-elements';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <StepContextProvider>
      <App />
    </StepContextProvider>
  </React.StrictMode>
);

