import { usePackageData } from "../hooks/usePackageData";
import InsuranceInfoLoader from "../utils/preloaders/insuranceInfoLoader";
import Div100vh from 'react-div-100vh';

//icons
import closeIcon from "../assets/closeIcon.svg";
import succesTick from "../assets/successful.svg";

const commafy = require('commafy');

export default function InsuranceInfoSideBar({ toggle, planId, next }) {

    const { isLoading, data, isError, error } = usePackageData(planId);
    
    const infoId = data?.data.data;

    const providerId = infoId?.provider.id;

    localStorage.setItem('provider', providerId);


    return (
        <Div100vh className='fixed top-0 left-0 h-screen w-screen bg-backgroundModal/60 flex justify-center items-center z-10'>
            <div className='bg-white w-screen overflow-scroll   md:w-[50vw] lg:w-[32vw] fixed right-0 h-[100vh] p-5 flex flex-col justify-between'>

            <div className='bg-white flex flex-col w-full h-full'>

                

                <div className='flex flex-col w-full h-full'>
                    <img onClick={toggle} src={closeIcon} alt="" className="object-cover max-w-[13px] cursor-pointer"/>

                    { isError && 
                    <div class="z-20 absolute alert bg-red-100 rounded-lg py-3 px-6 mt-20 text-base text-yellow-700 justify-center flex text-center items-center w-[90%] alert-dismissible fade show" role="alert">
                        <div class="flex-1 items-center justify-center text-center">
                            {error}
                        </div>
                        <div>
                            <button type="button" class="btn-close box-content text-yellow-900 border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-yellow-900 hover:bg-transparent hover:no-underline" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>
                    </div>
                    }
                    
 
                    {
                    isLoading ? <InsuranceInfoLoader /> :
                    <>
                    <div className="flex mt-8">
                        <img src={infoId?.provider.logo} alt="" className="rounded-full border border-[#E1EAFF] w-[45px] h-[45px] object-contain" />
                        <div className="ml-4">
                            <p className="text-sm">{infoId?.provider.name}</p>
                            <p><span className="text-1px">₦</span><span className="text-lg font-medium px-1">{commafy(infoId?.amount)}</span>{infoId?.name}</p>
                        </div>
                    </div>

                    <div>
                        <p className="text-sm mt-6 mb-2">Benefits</p>
                        
                        <ul className="bg-backgroundColor2 px-4 py-2 rounded overflow-scroll max-h-[65vh] xxs:max-h-[60vh] 2xl:max-h-[72vh]">
                            {infoId?.benefits.map((benefit) => (
                                <li className="flex font-thin items-start my-2 text-sm antialiased"><img src={succesTick} alt="" className="object-contain max-w-[16px] mr-4 py-1"/>{benefit.description}</li>
                            ))}
                        </ul>
                    </div>
                    </>

                    }


                </div>





                
            </div>
                <button onClick={next} disabled={isLoading} className={`${isLoading && "cursor-not-allowed"} flex items-center justify-center w-full`}>Purchase Insurance Plan</button>

            </div>
            </Div100vh>
    )
}















