export default function InputRange({ onChange, placeholder }) {
    return (
    <div class="relative">
        <input type="number" 
            onChange={onChange}
            id="floating_filled" 
            class="block 
            rounded
            px-2.5 
            py-1
            pt-5 
            w-full 
            text-sm 
            font-thin
            text-gray-900 
            bg-backgroundColor2 
            border-0 
            appearance-none 
            focus:outline-none focus:ring-0 
            focus:border-blue-600 peer" placeholder=" " />
        <label for="floating_filled" class="absolute text-sm text-gray-500 py-[0] dark:text-gray-400 duration-300 transform -translate-y-3 scale-75 top-3 z-2 origin-[0] left-2.5  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3 xs:text-xs">{placeholder}</label>
    </div>
    ) 
}


































