import { useState, useEffect, useContext } from 'react';
import { StepContext } from '../Context/stepFormContext/stepContext';

export default function Timer() {

    // const {initialMinute = 5,initialSeconds = 0} = props;
    const [ minutes, setMinutes ] = useState(5);
    const [seconds, setSeconds ] =  useState(0);

    const { setTimerStarts } = useContext(StepContext);

    useEffect(() => {
    let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    // clearInterval(myInterval)
                    setTimerStarts(false)

                    return
                    // return false;
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000)
        return ()=> {
            clearInterval(myInterval);
        };
    });

    return (
        <p className="text-muted">
            { minutes === 0 && seconds === 0
                ? null
                : <h1> {minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</h1>
            }
        </p>
    )
}

