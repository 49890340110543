import api from "../utils/axios-utils";
import { useContext, useState } from "react";
import { StepContext } from "../Context/stepFormContext/stepContext";
import Timer from "./Timer";

//assets
import arrow from "../assets/Group 6.svg";
import edit from "../assets/Vector.svg";
import resendIcon from "../assets/resend.svg";

import OtpInput from "react-otp-input";

export default function OtpVerification() {
  const [otp, setOtp] = useState("");
  // const [resendingOtp, setResendingOtp] = useState(false);
  const {
    setWelcomeStep,
    welcomeError,
    setWelcomeError,
    timerStarts,
    setTimerStarts,
    setEditMode,
  } = useContext(StepContext);

  const handleChange = (otp) => setOtp(otp);

  const PHONE = localStorage.getItem("phoneNo");
  const HASHEDNO = localStorage.getItem("hashedNo");

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setError(false);
    try {
      const res = await api.post("auth/phone/verify", {
        // phone_number: phoneNo,
        phone_number: PHONE,
        otp: otp,
      });

      localStorage.setItem("token", res.data.data.token);
      localStorage.setItem("initiatorId", res.data.data.id);
      localStorage.setItem("email", res.data.data.email);
      res.data && window.location.replace("insurance");
    } catch (err) {
      setWelcomeError(err.response.data.message);
    }
  };

  const resendOtp = async (e) => {
    e.preventDefault();
    // setError(false);

    try {
      await api.post("auth/phone/resend-otp", {
        email_or_phone_number: PHONE,
      });
      setTimerStarts(true);
    } catch (err) {
      setWelcomeError(err.response.data.message);
      // notify()
      // console.log(err.response.data.message);
    }
  };

  return (
    <div className="flex flex-col lg:w-[400px]">
      <div>
        <h1 className="text-2xl md:text-3xl mb-4 font-upgrade">
          Get Started in 2 steps
        </h1>
        <p className="text-sm max-w-xs text-neutral-400 mb-4">
          Please provide your phone number to get started
        </p>
      </div>

      <form
        onSubmit={handleSubmit}
        className="max-w-sm px-4 py-6 bg-white flex flex-col align md:items-start items-center border-t-2 border-[#2054D2] "
      >
        <div className="flex flex-col w-full">
          <div className=" flex justify-between text-base w-full mb-3">
            <p className="text-center text-sm">Phone Number Verification</p>
            {/* <p className="text-buttonColor pl-4">+{HASHEDNO}</p> */}
          </div>

          <div className="flex justify-between">
            <p className="text-sm max-w-xs mb-4 font-thin antialiased">
              Enter the 6 digit code sent to
            </p>
            <p className="text-buttonColor pl-4">+{HASHEDNO}</p>
          </div>
        </div>

        <OtpInput
          value={otp}
          hasErrored={welcomeError}
          errorStyle={{
            border: "1px solid red",
          }}
          onChange={handleChange}
          // onChange={e=>setOtp(e.target.value)}
          numInputs={6}
          separator={
            <span
              style={{
                width: "1px",
              }}
            ></span>
          }
          isInputNum={true}
          shouldAutoFocus={true}
          inputStyle={{
            width: "2rem",
            height: "2rem",
            margin: "0 0.4rem",
            fontSize: "1rem",
            borderRadius: 4,
            border: "1px solid rgba(0,0,0,0.3)",
            background: "#F3F5FC",
          }}
          className=""
          focusStyle={{
            border: "1px solid #CFD3DB",
            outline: "none",
          }}
        />

        {/* setWelcomeStep */}

        <div
          onClick={() => {
            setWelcomeStep(0);
            setEditMode(true);
          }}
          className="flex justify-between items-center w-full border p-1
            rounded-full px-2 text-black mt-4 hover:bg-backgroundColor hover:border-[#2054D2] cursor-pointer"
        >
          <div className="text-xs font-thin antialiased">
            Entered a wrong phone number? Edit.
          </div>
          <div>
            <img className="icon" src={edit} alt="" />
          </div>
        </div>

        {timerStarts ? (
          <div className=" my-6 flex   items-center w-full">
            <div className="text-sm font-thin antialiased text-[#828282]">
              Resend code in
            </div>
            <p className="text-[#2054D2] text-sm mx-[14px]">
              <Timer />
            </p>
          </div>
        ) : (
          <div className=" my-6 flex   items-center w-full">
            <div className="text-sm font-thin antialiased text-[#828282]">
              Didn't get code?
            </div>
            <img
              onClick={resendOtp}
              src={resendIcon}
              alt=""
              className="object-contain w-[11.67px] mx-[14px] cursor-pointer"
            />
            <p
              onClick={resendOtp}
              className="text-[#2054D2] text-sm cursor-pointer"
            >
              Resend Code
            </p>
          </div>
        )}

        <button className="flex items-center justify-center w-full">
          Continue <img className="pl-2" src={arrow} alt="" />
        </button>
      </form>
    </div>
  );
}
