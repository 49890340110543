import React, { useState, createContext } from "react";

// Create Context Object
export const StepContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const StepContextProvider = (props) => {
  const [currentModal, setCurrentModal] = useState(0);
  const [welcomeStep, setWelcomeStep] = useState(0);
  const [welcomeError, setWelcomeError] = useState(false);
  const [timerStarts, setTimerStarts] = useState(true);
  const [editMode, setEditMode] = useState(false);

  return (
    <StepContext.Provider
      value={{
        currentModal,
        setCurrentModal,
        welcomeStep,
        setWelcomeStep,
        welcomeError,
        setWelcomeError,
        timerStarts,
        setTimerStarts,
        editMode,
        setEditMode,
      }}
    >
      {props.children}
    </StepContext.Provider>
  );
};
